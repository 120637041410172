@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap");
.BtnLeft:hover .HiddenList {
}
#map { 
  height: 100%;
  width:100%;
 }
 .map{
  width: 80vw;
  height: 85vh;
 }
.ContainerNavbarOnHover {
  position: fixed;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
  border-bottom: 0.3px;
  border-color: white;
  border-style: solid;
  z-index: 10000;
  align-items: center;
  background-color: #1d1a1d;
  top: 0;
  transition: padding-bottom 0.5s ease-in, top 0.5s ease-in, top 0.5s ease-in, top 0.5s ease-in;
  padding-top: 0%;
  padding-bottom: 0%;
}
.LinkBackground {
  background-color: rgb(40 55 75);
  grid-column: 1/8;
  grid-row: 11/14;
  width: 100%;
}
.ContainerNavbarOnHover .nav-link {
  transform: rotate(180deg);
  top: 1%;
}
.PDFCont{
  width: 60%;
  padding-left: 20%;
  padding-right: 20%;
}
.HiddenListOnHover {
  padding-left: 0%;
  display: block;
  margin-top: 10%;
  opacity: 1;
  transition: margin-top 0.5s, opacity 0.5s ease-in;
}
.ContainerNavbarOnHover .blank {
  flex: 0.2;
}
.ContainerNavbarOnHover .Btn {
  padding-bottom: 2.5%;
}
.LandingImgCont {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.LandingImg {
  width: 100%;
}
.ContainerNavTwoOnHover {
  flex: 3;
}
.SecondaryLink:hover .LinkImg {
  transform: scale(1.05);
  opacity: 0.5;
}
.LinkOne:hover .LinkImg {
  transform: scale(1.05);
  opacity: 0.5;
}
.LinkTwo:hover .LinkImg {
  transform: scale(1.05);
  opacity: 0.5;
}
.Btn-Email:hover {
  cursor: pointer;
  color: #69a333;
}
::-webkit-scrollbar-thumb:hover {
  background: #32343d;
}

@media only screen and (max-width: 750px) {
  .ContainerNavbarOnHover {
    position: fixed;
    display: flex;
    width: 70%;
    min-width: fit-content;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    border-right: 0.3px;
    border-left: 0px;
    border-top: 0px;
    border-bottom: 0px;
    border-color: white;
    border-style: solid;
    align-items: center;
    left: 0%;
  }
  .ContainerNavbarOnHover .nav-link {
    top: auto;
  }
  .ContainerNavbarOnHover .nav-link {
    transform: rotate(90deg);
  }
  .ContainerNavbarOnHover .BtnLeft {
    display: block;
  }

  .ContainerNavbarOnHover .Btn {
    display: block;
    margin-right: 0%;
  }

  .Btn:hover .HiddenList {
    margin-top: 0%;
    z-index: 100;
  }
}

@media (hover: none) {
  @media only screen and (max-width: 750px) {
    .ContainerNavbar:focus {
      position: fixed;
      display: flex;
      width: 70%;
      min-width: fit-content;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      border-right: 0.3px;
      border-left: 0px;
      border-top: 0px;
      border-bottom: 0px;
      border-color: white;
      border-style: solid;
      align-items: center;
      left: 0%;
    }
    .ContainerNavbar:focus .nav-link {
      top: auto;
    }
    .ContainerNavbar:focus .nav-link {
      transform: rotate(90deg);
    }
    .ContainerNavbar:focus .BtnLeft {
      display: block;
    }

    .ContainerNavbar:focus .Btn {
      display: block;
      margin-right: 0%;
    }

    .Btn:hover .HiddenList {
      margin-top: 0%;
      z-index: 100;
    }
  }
}
body {
  background-color: white;
  overflow: hidden;
}
ul {
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0em;
}
html {
  overflow-y: scroll;
}
.parallax {
  /* The image used */
  background-image: url("..//public/Pictures/DJI_0075.webp");

  /* Full height */
  height: 100%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallaxSeedWheat {
  background-image: url("..//public/Pictures/Wheat.webp");
  height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallaxCFV {
  background-image: url("..//public/Pictures/Climate.webp");
  height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallaxAlf {
  background-image: url("..//public/Pictures/Alf.webp");
  height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallaxChannel {
  background-image: url("..//public/Pictures/ChannelCorn.webp");
  height: 100vh;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallaxPivot {
  background-image: url("..//public/Pictures/Pivot.webp");
  height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallaxTopDown {
  background-image: url("..//public/Pictures/TopDown.webp");
  height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.ReportImgCont {
  padding-top: 10%;
  padding-bottom: 10%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.ReportImgTitle {
  width: 100%;
  color: white;
  text-align: center;
  padding-bottom: 0%;
  padding-top: 2%;
  color: #fff;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, var(--lmnt-theme-primary-font, "Helvetica Neue"), Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
  letter-spacing: 2px;
  font-weight: 600;
  font-style: normal;
  font-size: 1vw;
}
.ReportImgText {
  width: 100%;
  color: white;
  text-align: center;
  padding-bottom: 0%;
  padding-top: 2%;
  color: #fff;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, var(--lmnt-theme-primary-font, "Helvetica Neue"), Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
  letter-spacing: 2px;
  font-weight: 600;
  font-style: normal;
  font-size: 1vw;
}
.ReportImg {
  width: 80%;
  padding: 5%;
  padding-top: 2%;
}
.parallaxVRS {
  background-image: url("..//public/Pictures/TopDown.webp");
  height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallaxVR2 {
  background-image: url("..//public/Pictures/vrs2.webp");
  height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallaxGSS {
  background-image: url("..//public/Pictures/gss.webp");
  height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.Container {
  width: 100%;
  height: 100%;
}
.nav-link-cont {
  position: fixed;
  left: 4%;
  top: 4%;
  transition: top 0.5s ease-in
}
.nav-link {
  display: flex;
  color: var(--text-primary);
  text-decoration: none;
  filter: grayscale(100%) opacity(0.9);
  transition: var(--transition-speed);
  width: 0%;
  color: white;
  margin: auto;
  transition: transform 400ms ease;
  justify-content: center;
  top: -5%;
}
.ContainerNavbar {
  position: fixed;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
  border-bottom: 0.3px;
  border-color: white;
  border-style: solid;
  z-index: 10000;
  align-items: center;
  background-color: #1d1a1d;
  top: calc(-15% - 1.5px);
  padding-bottom: 12vh;
  transition: padding-bottom 0.5s ease-in, top 0.5s ease-in,top 0.5s ease-in, top 0.5s ease-in;
}
.ContainerHeader {
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
  border-bottom: 0.3px;
  background-color: rgb(105, 163, 51);
  border-color: white;
  border-style: solid;
  height: 8.5%;
  z-index: 0;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      #32343d,
      #2e2f37,
      #2a2b31,
      #26262c,
      #222226,
      #222226,
      #222226,
      #222226,
      #26262c,
      #2a2b31,
      #2e2f37,
      #32343d
    )
    1;
  border-top-width: 0px;
  border-bottom-width: 0.3rem;
  border-left-width: 0px;
  border-right-width: 0px;
}

.ContainerNavOne {
  display: flex;
  flex: 1;
  justify-content: center;
}
.ContainerNavTwo {
  display: flex;
  flex: 2;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
}
.FlexEmpty {
  flex: 1;
}
.NavBtnFlex {
  margin-top: 1.5%;
  margin-bottom: 0;
  margin-left: 6%;
  margin-right: 12%;
  list-style: none;
  justify-content: center; /* align horizontal */
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  align-items: flex-start;
}
.NavTitle {
  color: white;
  font-size: 2vw;

  font-family: raleway;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: 1vw;
}
.Btn {
  padding-top: 2%;
  padding-bottom: 2%;
  text-align: center;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  transition: padding-top 0.5s, padding-bottom 0.5s;
}
.BtnLetsTalk{
  padding-top: 1%;
  padding-bottom: 1%;
  text-align: center;
  width: 7.5%;
  flex: 1;
  align-items: center;
  justify-content: center;
  transition: padding-top 0.5s, padding-bottom 0.5s;
  background-color: rgb(105, 163, 51);
  border-radius: 20%;
  height: 20px;
  position: fixed;
  right: 4%;
  top:.5%;
  margin-top: 1.5%;
  transition: top 0.5s ease-in,margin-top .5s ease-in;
}
.ContainerNavbarOnHover .BtnLetsTalk {
  top:0%;
  margin-top: 2.5%;
}
.BtnS {
  align-self: flex-end;
  text-align: center;
  width: 0%;
  flex: 0.5;
  align-items: center;
  justify-content: center;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  transition: padding-top 0.5s, padding-bottom 0.5s;
}
.BtnLeft {
  display: block;
  flex: 1;
  text-decoration: none;
  color: white;
  height: fit-content;
  width: fit-content;
  font-size: 1.5vw;
  font-family: Raleway;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: medium;
  margin: auto;
}
.HiddenListItem {
  text-decoration: none;
  color: white;
  font-size: 1vw;
  font-family: Raleway;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: 1vw;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  list-style-type: none;
}
.HiddenList {
  padding-left: 0;
  display: block;
  margin-top: -75%;
  opacity: 0.01;
  transition: margin-top 0.5s, opacity 0.1s ease-in;
}
.triangleBR {
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  border-style: solid;
  border-width: 0 0 7vw 100vw;
  border-color: transparent transparent #d7d7d7 transparent;
}
.triangleBR2 {
  position: absolute;
  width: 0;
  height: 0;
  bottom: -1px;
  z-index: 100;
  border-style: solid;
  border-width: 0 0 6vw 100vw;
  border-color: transparent transparent #1d1a1d transparent;
}
.triangleBL {
  width: 0;
  height: 0;
  border-style: none;
  border-width: 7vw 100vw 0 0;
  border-color: #d7d7d7 transparent transparent transparent;
}

.triangleBL2 {
  position: absolute;
  width: 0;
  height: 0;
  z-index: 100;
  border-style: solid;
  border-width: 6vw 98vw 0 0;
  border-color: #1d1a1d transparent transparent transparent;
}
.HomeTriangle{

}
.triangleTL2{
  position: absolute;
  width: 0;
  height: 0;
  z-index: 101;
  border-style: solid;
  border-width: 0 100vw 20vw 0;
  border-color: transparent rgb(105, 163, 51) transparent  transparent;
  
}
.triangleTR2{
  position: absolute;
  width: 0;
  height: 0;
  z-index: 100;
  border-style: solid;
  border-width: 20vw 100vw 0 0;
  border-color: rgb(105, 163, 51) transparent transparent transparent;
}
.ContainerHome {
  height: 100%;
  width: 100%;
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
}
.LogoHomeCont {
  justify-content: center;
  width: 100%;
  height: 50%;
  position: relative;
  margin-bottom: -0.5vw;
  background-image: linear-gradient(
    to right,
    #32343d,
    #2e2f37,
    #2a2b31,
    #26262c,
    #222226,
    #222226,
    #222226,
    #222226,
    #26262c,
    #2a2b31,
    #2e2f37,
    #32343d
  );
}
.LogoHomeOp {
  position: absolute;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgb(0, 0, 0, 1));
  width: 100%;
  height: 100%;
  z-index: 10;
  position: 0;
  opacity: 0.7;
}
.HomeTitle {
  border-width: 1px;
  padding: 1%;
  border-style: solid;
  font-family: raleway;
  justify-content: center;
  position: absolute;
  z-index: 100;
  color: white;
  font-size: 3vw;
  position: absolute;
  height: fit-content;
  width: max-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.HomeTitleLogo {
  padding: 1%;
  justify-content: center;
  position: absolute;
  z-index: 100;
  position: absolute;
  width: max-content;
  aspect-ratio: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: inherit;
}
.Cont {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
}
.SecondaryLink {
  /* margin-top: 5%; */
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  margin-left: 0;
  margin-left: 5%;
  grid-column: 1/4;
  grid-row: 11/14;
  border-radius: 10px;
  width: 95%;
  font-size: 1.5vw;
  background-image: linear-gradient(
    to bottom,
    #32343d,
    #2c2b32,
    #242227,
    #1d1a1d,
    #141113,
    #141113,
    #141113,
    #141113,
    #1d1a1d,
    #242227,
    #2c2b32,
    #32343d
  );
  text-decoration: none;
  overflow: hidden;
}
.LinkOne {
  margin-top: 5%;
  flex-direction: column;
  margin-bottom: 5%;
  margin-left: 25%;
  grid-column: 1/4;
  grid-row: 11/14;
  border-radius: 10px;
  width: 100%;
  font-size: 1.5vw;
  background-image: linear-gradient(
    to bottom,
    #32343d,
    #2c2b32,
    #242227,
    #1d1a1d,
    #141113,
    #141113,
    #141113,
    #141113,
    #1d1a1d,
    #242227,
    #2c2b32,
    #32343d
  );
  text-decoration: none;
  overflow: hidden;
}
.LinkImgCont {
  overflow: hidden;
}
.LinkImg {
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
  opacity: 1;
  height: 100%;
  margin-bottom: 1%;
}

.LinkImg {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.LinkText {
  width: 90%;
  border-image: linear-gradient(
      to right,
      #32343d,
      #55555d,
      #7a7980,
      #a09fa4,
      #c8c7ca,
      #c8c7ca,
      #c8c7ca,
      #c8c7ca,
      #a09fa4,
      #7a7980,
      #55555d,
      #32343d
    )
    1;
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-style: solid;
  padding: 5%;
  padding-top: 2%;
  color: white;
  font-family: Raleway, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: 0.85vw;
}
.LinkTextTitle {
  width: 90%;
  border-image: linear-gradient(
      to right,
      #32343d,
      #55555d,
      #7a7980,
      #a09fa4,
      #c8c7ca,
      #c8c7ca,
      #c8c7ca,
      #c8c7ca,
      #a09fa4,
      #7a7980,
      #55555d,
      #32343d
    )
    1;
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-style: solid;
  padding: 5%;
  color: white;
  font-family: Raleway, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: 1.15vw;
  text-align: center;
}
.SecondaryTextBox {
  margin-left: 75px;
  padding: 0%;
  padding-top: 0%;
  color: black;
  font-family: Raleway, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: 0.85vw;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
}
.pictureModel{
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  background-color: white;
  z-index: 100000;
}
.pictureModelCont{
  text-align: center;
}
.mapPopupImg{
  max-height: 100vh;
}
.SecondaryTextBoxLink {
  /* margin-top: 125px; */
  margin-left: 75px;
  padding: 0%;
  padding-top: 0%;
  color: black;
  font-family: Raleway, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: 0.85vw;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  padding-bottom: 5%;
}
.SecondaryTextBoxGSS {
  padding: 0%;
  padding-top: 0%;
  color: black;
  font-family: Raleway, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: 0.85vw;
  margin-left: 0;
  /* margin-right: 5vw; */
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.SecondaryBody {
  display: flex;
  border-image: linear-gradient(
      to right,
      #32343d,
      #55555d,
      #7a7980,
      #a09fa4,
      #c8c7ca,
      #c8c7ca,
      #c8c7ca,
      #c8c7ca,
      #a09fa4,
      #7a7980,
      #55555d,
      #32343d
    )
    1;
  border-style: solid;
  border-width: 0px;
}
.SecondaryBodyLink {
  display: flex;
  border-image: linear-gradient(
      to right,
      #32343d,
      #55555d,
      #7a7980,
      #a09fa4,
      #c8c7ca,
      #c8c7ca,
      #c8c7ca,
      #c8c7ca,
      #a09fa4,
      #7a7980,
      #55555d,
      #32343d
    )
    1;
  border-style: solid;
  border-width: 0px;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.SecondaryGrid {
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
  width: 95%;
  border-image: linear-gradient(
      to right,
      #32343d,
      #55555d,
      #7a7980,
      #a09fa4,
      #c8c7ca,
      #c8c7ca,
      #c8c7ca,
      #c8c7ca,
      #a09fa4,
      #7a7980,
      #55555d,
      #32343d
    )
    1;
  border-width: 1px;
  border-style: solid;
  padding: 1%;
}
.SecondaryTextTitle {
  color: white;
  padding-bottom: 1%;
  letter-spacing: 1px;
  font-weight: 500;
  font-style: normal;
  font-size: 4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding-bottom: 5%;
  padding-top: 25%;
}
.SecondaryTextTitleGSS {
  flex: 1 1;
  padding-right: 5%;
  letter-spacing: 1px;
  font-weight: 500;
  font-style: normal;
  color: white;
  font-size: 2.5vw;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.SecondaryText {
  /*
  width: 100%;
  padding-bottom: 0%;
  padding-top: 2%;
  color: black;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, var(--lmnt-theme-primary-font, "Helvetica Neue"), Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
  letter-spacing: 1px;
  font-weight: 300;
  font-style: normal;
  font-size: 1em;*/
  width: 100%;
  padding-bottom: 0%;
  padding-top: 2%;
  color: #fff;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, var(--lmnt-theme-primary-font, "Helvetica Neue"), Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
  letter-spacing: 2px;
  font-weight: 600;
  font-style: normal;
  font-size: large;
}
.SecondaryTextLine {
  /* margin-top: -70px; */
  /* margin-top: 125px; */
  padding-left: 75px;
  color: black;
  font-family: Raleway, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: 0.85vw;
  border-image: linear-gradient(
      to right,
      #32343d,
      #55555d,
      #7a7980,
      #a09fa4,
      #c8c7ca,
      #c8c7ca,
      #c8c7ca,
      #c8c7ca,
      #a09fa4,
      #7a7980,
      #55555d,
      #32343d
    )
    1;
  /* border-top-width: 1px; */
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-style: solid;
  display: flex;
  flex-direction: row;
  flex: 1;
  /* background-color: brown; */
  /* justify-content: center; */
  height: fit-content;
  /* color: blanchedalmond; */
  border-radius: 5px;
  margin-right: 10px;
}
.SecondaryTextCont {
  width: 100%;
  padding-right: 2%;
  flex: 1;
}
.SecondaryTextLineLink {
  /* margin-top: -70px; */
  max-width: 1000px;
  color: black;
  font-family: Raleway, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: 0.85vw;
  border-image: linear-gradient(
      to right,
      #32343d,
      #55555d,
      #7a7980,
      #a09fa4,
      #c8c7ca,
      #c8c7ca,
      #c8c7ca,
      #c8c7ca,
      #a09fa4,
      #7a7980,
      #55555d,
      #32343d
    )
    1;
  /* border-top-width: 1px; */
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-style: solid;
  display: flex;
  flex-direction: row;
  /* background-color: brown; */
  /* justify-content: center; */
  height: fit-content;
  /* color: blanchedalmond; */
  border-radius: 5px;
  margin-right: 10px;
}
.SecondaryImgCont {
  flex: 1;
}
.SecondaryImg {
  max-width: 45vw;
  max-height: 500px;
}
.InfoImgCont {
  flex: 1;
  padding: 0.5%; /*
  background-color: #1d1a1d;
  border-width: 0px;
  border-color: rgb(105, 163, 51);
  border-style: solid;
  border-bottom-width: 10px;*/
  border-radius: 5px;
  height: fit-content;
  width: fit-content;
}
.InfoImgflex {
  display: flex;
  justify-content: "center";
  align-items: "center";
}
.LinkTwo {
  text-decoration: none;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 7%;
  margin-right: 32%;
  font-size: 1.5vw;
  grid-column: 5/8;
  grid-row: 11/14;
  border-radius: 10px;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    #32343d,
    #2c2b32,
    #242227,
    #1d1a1d,
    #141113,
    #141113,
    #141113,
    #141113,
    #1d1a1d,
    #242227,
    #2c2b32,
    #32343d
  );
  overflow: hidden;
}
.special {
  text-decoration: none;
  padding-top: 3%;
  border-bottom: 0;
  display: block;
  font-family: Raleway, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: 0.75vw;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: rgb(105, 163, 51);
}
.HomeEmpty {
  grid-column: 1/7;
  grid-row: 2/4;
  width: 100%;
  height: 100%;
}
.LinkHomeCont {
  width: 100%;
}
.LinkHome {
  width: 50%;
}
.HomeBar {
  grid-column: 1/8;
  grid-row: 1;
  width: 100%;
  height: 5rem; /*
  background-color: rgb(105, 163, 51);
  border-style: solid;
  border-image: linear-gradient(
      to right,
      #32343d,
      #2e2f37,
      #2a2b31,
      #26262c,
      #222226,
      #222226,
      #222226,
      #222226,
      #26262c,
      #2a2b31,
      #2e2f37,
      #32343d
    )
    1;
  border-top-width: .3rem;
  border-bottom-width: .3rem;
  border-left-width: 0px;
  border-right-width: 0px;*/
}
.HomeVideo {
  /* max-width: 90%; */
  display: flex;
  justify-content: center;
  grid-column: 1/8;
  grid-row: 2/5;
  /* padding: 2%; */
  /* margin: 2%; */
  /* background-color: #d7d7d7; */
  /* border-width: 0.3rem; */
  /* border-style: solid; */
  border-color: rgb(105, 163, 51);
  border-radius: 10px;
}
.HomeCaras {
  border-radius: 20px;
  padding: 2.5%;
  margin-top: 2%;
  margin-left: 0%;
  margin-right: 0%;
  grid-column: 2/7;
  grid-row: 15;
  width: 80%;
  margin-bottom: 5%;
  background-image: linear-gradient(
    to bottom,
    #32343d,
    #2c2b32,
    #242227,
    #1d1a1d,
    #141113,
    #141113,
    #141113,
    #141113,
    #1d1a1d,
    #242227,
    #2c2b32,
    #32343d
  );
  border-style: solid;
  border-width: 4px;
  border-color: rgb(105, 163, 51);
}

.HomeCarasTest {
  border-radius: 20px;
  padding: 1%;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 0%;
  margin-right: 0%;
  width: 80%;
  background-image: linear-gradient(
    to bottom,
    #32343d,
    #2c2b32,
    #242227,
    #1d1a1d,
    #141113,
    #141113,
    #141113,
    #141113,
    #1d1a1d,
    #242227,
    #2c2b32,
    #32343d
  );
  border-style: solid;
  border-width: 4px;
  border-color: rgb(105, 163, 51);
}
.vidCont {
  grid-column: 1/8;
  grid-row: 1/5;
  width: 100%;
  height: 100%;
  background-color: #1d1a1d;
  justify-content: center;
  align-items: center;
  display: flex;
}
.HomeVideoTwo {
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.HomeBarTwo {
  grid-column: 1/8;
  grid-row: 5;
  width: 100%;
  height: 4.5rem;
  z-index: 2;
  background-color: rgb(105, 163, 51);
  border-image: linear-gradient(
      to right,
      #32343d,
      #2e2f37,
      #2a2b31,
      #26262c,
      #222226,
      #222226,
      #222226,
      #222226,
      #26262c,
      #2a2b31,
      #2e2f37,
      #32343d
    )
    1;
  border-top-width: 0px;
  border-bottom-width: 0.3rem;
  border-left-width: 0px;
  border-right-width: 0rem;
  /*
  background-color: rgb(105, 163, 51);
  border-style: solid;
  border-image: linear-gradient(
      to right,
      #32343d,
      #2e2f37,
      #2a2b31,
      #26262c,
      #222226,
      #222226,
      #222226,
      #222226,
      #26262c,
      #2a2b31,
      #2e2f37,
      #32343d
    )
    1;
  border-top-width: .3rem;
  border-bottom-width: .3rem;
  border-left-width: 0px;
  border-right-width: 0px;*/
}
.LogoOne {
  margin-top: -15%;
  grid-column: 3 / 6; /*1/4*/
  grid-row: 13;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  font-size: 1.5vw;
}
.LogoTwo {
  grid-column: 3 / 6; /*1/4*/
  grid-row: 14;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  font-size: 1.5vw;
}
.Two {
  width: 90%;
  padding-left: 10%;
  padding: 5%;
  padding-left: 10%;
  padding-right: 10%;
  justify-content: center;
  align-items: center;
  display: table-cell;
  vertical-align: middle;
  /* font-family: Raleway, Helvetica, sans-serif; */
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: x-large;
  flex: 1.5 1;
  text-align: initial;
}
.TwoListTitle {
  width: 90%;
  padding: 5%;
  justify-content: center;
  align-items: center;
  display: table-cell;
  vertical-align: middle;
  font-family: Raleway, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: 1vw;
  text-align: initial;
  padding-bottom: 0%;
}

.halfBorder {
  width: 55%;
  margin-left: 40%;
  border-width: 0px;
  border-bottom-width: 3px;
  border-style: solid;
  border-color: #31323a;
  display: flex;
  align-self: flex-start;
}
.TwoList {margin-top: 0;
  /* width: 90%; */
  padding: 5%;
  justify-content: center;
  align-items: center;
  vertical-align: top;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: x-large;
  flex: 4 1;
  /* text-align: initial; */
  list-style: none;
  padding-top: 0%;
  justify-content: center;
  padding-bottom: 0%;

}
.TwoCont {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.TwoListCont {
  flex: 4;
  display: flex;
  flex-direction: column;
}
.TwoTitleCont {
  justify-content: flex-end;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.TwoTitle {
  padding: 1%;
  width: 98%;
  font-family: Raleway, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: bold;
  font-style: normal;
  font-size: xx-large;
  display: flex;
  align-self: flex-end;
  justify-content: center;
}
.TextTwo {
  /* border-style: solid; */
  border-width: 5px;
  border-color: #32333d;
  /* padding-left: 3%; */
  /* margin-top: 3%; */
  flex-direction: column;
  align-content: flex-start;
  justify-content: space-around;
  display: flex;
  /* padding-right: 25%; */
  /* grid-column: 5 / 8; */
  grid-column: 1 / 8;
  grid-row: 16;
  width: 100%;
  color: white;
  font-size: 1.5vw;
  text-align: center;
  background-color: rgb(105, 163, 51);
  min-height: 60vh;
}
.textBox {
  justify-items: center;
  align-items: center;
  grid-column: 1/7;
  grid-row: 6;
  width: 100%;
  height: 100%;
}
.HomebarVertLeft {
  justify-self: flex-start;
  grid-column: 1;
  grid-row: 13/16;
  height: 100%;
  width: 5rem;
  z-index: 1;
  display: none;
  background-color: #69a333;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      #32343d,
      #2e2f37,
      #2a2b31,
      #26262c,
      #222226,
      #222226,
      #222226,
      #222226,
      #26262c,
      #2a2b31,
      #2e2f37,
      #32343d
    )
    1;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0.3rem;
}
.HomeBarThree {
  z-index: 2;
  grid-column: 1/8;
  grid-row: 17;
  width: 100%;
  height: 5rem;
  /*background-color: rgb(105, 163, 51);
  border-style: solid;
  */
  border-image: linear-gradient(
      to right,
      #32343d,
      #2e2f37,
      #2a2b31,
      #26262c,
      #222226,
      #222226,
      #222226,
      #222226,
      #26262c,
      #2a2b31,
      #2e2f37,
      #32343d
    )
    1;
  border-top-width: 0.3rem;
  border-bottom-width: 0.3rem;
  border-left-width: 0px;
  border-right-width: 0px;
}
.HomeCont1{   
  /* background-color: #28374b; */
  grid-column: 1 / 8;
  /* grid-row: 10 / 13; */
  height: 20vw;
}
.HomeContCont{
  width: 100vw;
  height: 100%;
}
.HomeCont3 {
  display: flex;
  background-color: #69a333;
  grid-column: 1 / 8;
  grid-row: 6 / 12;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column-reverse;
  padding-top: 5%;
}
.HomeCont3Text {
  font-size: 1vw;
}
.HomeCont3TitleText {
  font-size: 1.5vw;
}
.HomeCont4 {
  grid-column: 1/8;
  grid-row: 17;
  height: 100%;
  width: 100%;
  background-color: #69a333;
}
.HomeCont4Text {
  font-size: x-large;
}
.HomeCont4TitleText {
  font-size: xx-large;
}
.btn_class{
  border-width: 0px;
  background-color: transparent;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.closeBtn{
  position: fixed;
  right: 10px;
  top: 10px;
  border-radius: 50%;
  border-style: none;
  background-color: red;
  color: white;
  height: 40px;
  font-size: x-large;
  width: 40px;
  font-weight: bold;

}
.Home3TextCont {
  /*background: rgba(0, 0, 0, 0.5);*/
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 40vh;
}
.HomeBarFour {
  grid-column: 1/8;
  grid-row: 14;
  width: 100%;
  height: 5rem;
  background-color: rgb(105, 163, 51);
  border-style: solid;
  border-image: linear-gradient(
      to right,
      #32343d,
      #2e2f37,
      #2a2b31,
      #26262c,
      #222226,
      #222226,
      #222226,
      #222226,
      #26262c,
      #2a2b31,
      #2e2f37,
      #32343d
    )
    1;
  border-top-width: 0.3rem;
  border-bottom-width: 0.3rem;
  border-left-width: 0px;
  border-right-width: 0px;
  margin-bottom: 3%;
}
.HomeEmptyThree {
  padding: 2%;
  width: 100%;
  height: 100%;
  margin-left: 40%;
  font-size: 3vw;
  font-family: Raleway, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  /*font-size: 1vw;*/
  color: rgb(105, 163, 51);
}
.HomeEmptyFour {
  padding: 1%;
  font-family: Raleway, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: 1vw;
  color: rgb(105, 163, 51);
}
.HomeEmail {
  grid-column: 2/7;
  border-radius: 10px;
  background-image: linear-gradient(
    to bottom,
    #32343d,
    #2c2b32,
    #242227,
    #1d1a1d,
    #141113,
    #141113,
    #141113,
    #141113,
    #1d1a1d,
    #242227,
    #2c2b32,
    #32343d
  );
  grid-row: 15/16;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-items: center;
}
.HomeEmailTwo {
  margin-left: 5%;
  margin-bottom: 5%;
  width: 90%;
  height: 90%;
}
.LogoHome {
  width: 100%;
}
.GridPhoto {
  width: 100%;
  max-width: 4000px;
}
.App {
  width: 100%;
  height: 100%;
}
.FromName {
  width: 100%;
  height: 10px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
  font-family: Raleway, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: 1vw;
}
.FromNum {
  width: 100%;
  height: 10px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
  font-family: Raleway, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: 1vw;
}
.Message {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
  font-family: Raleway, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: 1vw;
}
.Email {
  width: 47.5%;
  height: 10px;
  padding: 12px 0.3rem;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
  font-family: Raleway, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: 1vw;
}
::placeholder {
  color: rgb(105, 163, 51);
}
:-ms-input-placeholder {
  color: rgb(105, 163, 51);
}

::-ms-input-placeholder {
  color: rgb(105, 163, 51);
}
.Submit {
  margin-left: 5%;
  width: 47.5%;
  height: 28px;
  padding: 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
  font-family: Raleway, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: 1vw;
  color: rgb(105, 163, 51);
}
.Footer-foot {
  height: 100%;
  columns: 1;
  display: flex;
  flex-direction: column;
  padding-inline-start: 0px;
  margin-block-start: 0%;
  margin-block-end: 0%;
}
.FooterLogo {
  width: 100%;
  height: 100%;
}
.Footer-Contact {
  /* padding-top: 8rem; */
  margin-bottom: 5%;
  text-decoration: none;
  list-style: none;
  height: 90%;
  grid-column: 5/6;
  color: white;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  text-align: center;
}
.Footer-Contact-Title {
  margin-bottom: 1%;

  flex: 1;
  text-align: inherit;
  width: 100%;
  height: 100%;
  font-family: Raleway, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
}

.Footer-Contact-Info {
  margin-bottom: 5%;
  flex: 1;
  text-align: inherit;
  width: 100%;
  height: 100%;
  font-family: Raleway, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: large;
}
.Footer-Contact-Info-Phone {
  margin-bottom: 5%;
  flex: 1;
  text-align: inherit;
  width: 100%;
  height: 100%;
  letter-spacing: 1px;
  font-weight: 500;
  font-style: normal;
  font-size: large;
}

.Footer-Contact-Empty {
  flex: 2;
  width: 100%;
  height: 100%;
}
.Btn-Email {
  background-color: #141113;
  color: white;
  text-decoration: underline;
}

.ContainerFooter {
  margin-top: 0%;
  position: absolute;
  width: 100%;
  display: grid;
  background-color: #141113;
  align-items: end;
  grid-template-columns: 2fr 1fr 1fr 1fr 2fr;
}
.privacyLink {
  margin-left: 5%;
  color: white;
  font-size: 2vh;
}
ul {
  padding-inline-start: 10px;
  margin-block-start: 2%;
  margin-block-end: 2%;
}
.Copyright {
  margin-left: 5%;
  color: white;
  font-size: 2vh;
  padding-bottom: 5%;
}
.Policy {
  font-size: 2vw;
  margin: 0%;
  margin-top: 0%;
  padding-top: 0%;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
.InfoBar {
  display: none;
  z-index: 2;
  height: 5rem;
  width: 100vw;
  background-color: rgb(105, 163, 51);
  border-style: solid;
  border-image: linear-gradient(
      to right,
      #32343d,
      #2e2f37,
      #2a2b31,
      #26262c,
      #222226,
      #222226,
      #222226,
      #222226,
      #26262c,
      #2a2b31,
      #2e2f37,
      #32343d
    )
    1;
  border-top-width: 0.3rem;
  border-bottom-width: 0.3rem;
  border-left-width: 0px;
  border-right-width: 0px;
}
.GridCont {
  background-color: rgb(40 55 75);
  padding-left: 100px;
  padding-top: 100px;
  justify-content: space-evenly;
  display: grid;
  flex-wrap: wrap;
  min-height: 200px;
}
.GridContLong {
  margin-top: 100px;
  width: 100%;
  justify-content: space-evenly;
  display: grid;
  flex-wrap: wrap;
  max-width: 1000px;
}
.GridTextbox {
  margin-top: 10%;
  margin-bottom: 5%;
  border-radius: 30px;
  width: 80%;
  margin-left: 5%;
  padding: 5%;
  grid-column: 1/8;
  grid-row: 1;
  background-image: linear-gradient(
    to bottom,
    #32343d,
    #2c2b32,
    #242227,
    #1d1a1d,
    #141113,
    #141113,
    #141113,
    #141113,
    #1d1a1d,
    #242227,
    #2c2b32,
    #32343d
  );
}
.GridText {
  font-size: 2.5vh;
  color: white;
  font-family: Raleway, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: 1vw;
}
.GridLogoCont {
  margin-left: 5%;
  width: 90%;
  background-color: white;
}
.GridLogo {
  margin-left: 37.5%;
  width: 25%;
}

.GridTextTitle {
  text-align: center;
  font-size: 3.5vh;
  padding: 0%;
  padding-top: 2%;
  color: white;
  font-family: Raleway, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
  font-style: normal;
  font-size: 1vw;
}
.GridSample1 {
  width: 60%;
  margin-left: 15%;
  padding: 5%;
  padding-bottom: 0%;
  grid-column: 1/8;
  grid-row: 3;
}
.GridSample2 {
  width: 60%;
  margin-left: 18%;
  padding: 2%;
  grid-column: 1/8;
  grid-row: 5;
}
.GridSample3 {
  width: 60%;
  margin-left: 18%;
  padding: 2%;
  grid-column: 1/8;
  grid-row: 7;
}
.GridSample4 {
  width: 60%;
  margin-left: 18%;
  padding: 2%;
  grid-column: 1/8;
  grid-row: 9;
}
.GridBar1 {
  grid-column: 1/8;
  grid-row: 2;
  width: 100%;
  height: 95%;
  min-height: 50px;
  background-color: rgb(105, 163, 51);
  border-style: solid;
  border-image: linear-gradient(
      to right,
      #32343d,
      #2e2f37,
      #2a2b31,
      #26262c,
      #222226,
      #222226,
      #222226,
      #222226,
      #26262c,
      #2a2b31,
      #2e2f37,
      #32343d
    )
    1;
  border-width: 10px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.GridBar2 {
  grid-column: 1/8;
  min-height: 50px;
  grid-row: 4;
  width: 100%;
  height: 95%;
  background-color: rgb(105, 163, 51);
  border-style: solid;
  border-image: linear-gradient(
      to right,
      #32343d,
      #2e2f37,
      #2a2b31,
      #26262c,
      #222226,
      #222226,
      #222226,
      #222226,
      #26262c,
      #2a2b31,
      #2e2f37,
      #32343d
    )
    1;
  border-width: 10px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.GridBar3 {
  grid-column: 1/8;
  min-height: 50px;
  grid-row: 6;
  width: 100%;
  height: 95%;
  background-color: rgb(105, 163, 51);
  border-style: solid;
  border-image: linear-gradient(
      to right,
      #32343d,
      #2e2f37,
      #2a2b31,
      #26262c,
      #222226,
      #222226,
      #222226,
      #222226,
      #26262c,
      #2a2b31,
      #2e2f37,
      #32343d
    )
    1;
  border-width: 10px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.GridBar4 {
  grid-column: 1/8;
  min-height: 50px;
  grid-row: 8;
  width: 100%;
  height: 95%;
  background-color: rgb(105, 163, 51);
  border-style: solid;
  border-image: linear-gradient(
      to right,
      #32343d,
      #2e2f37,
      #2a2b31,
      #26262c,
      #222226,
      #222226,
      #222226,
      #222226,
      #26262c,
      #2a2b31,
      #2e2f37,
      #32343d
    )
    1;
  border-width: 10px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.Logo {
  margin-left: 20%;
  margin-bottom: 5%;
  width: 60%;
  max-width: 4000px;
}
/* Handle on hover */

.backImg {
  max-height: 300px;
  max-width: 100%;
  position: absolute;
  left: -100px;
  top: 100vh;
}
.gridContInfoPage {
  padding-top: 100px;
  min-height: 200px;
  width: 100%;
  display: grid;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 5%;
  background-color: rgb(40 55 75);
}

@media only screen and (min-width: 1250px) {
  .SecondaryTextBox {
    flex-direction: column;
  }
}
@media only screen and (max-width: 1100px) {
  .gridContInfoPage {
    padding-top: 0%;
  }
  .GridCont {
    padding-top: 175px;
  }
  .GridLogoCont {
    margin-left: 0%;
  }
  .GridLogo {
    margin-left: 25%;
    width: 50%;
  }
  .SecondaryTextBox {
  }
  .SecondaryTextTitle {
    z-index: 1;
    margin-top: 10px;
    margin-left: 200px;
    font-size: 3em;
    position: absolute;
    top: 100px;
    width: calc(100% - 175px);
    margin-left: 0px;
    /* width: 0px; */
    justify-content: center;
  }
  .InfoImgCont {
  }
  .LinkHomeCont {
    width: 90%;
    margin-left: 5%;
  }
  .LinkHome {
    width: 100%;
  }
  .LinkText {
    font-size: 2vw;
  }
  .LinkTextTitle {
    font-size: 3.5vw;
  }
  .HomeCont3 {
    grid-row: 5;
    padding-bottom: 10%;
  }
  .HomeCont3Text {
    font-size: 2.5vw;
  }
  .HomeCont3TitleText {
    font-size: 3.5vw;
  }
  .BtnLetsTalk{
    height: auto;
    width: 11%;
    border-width: 1px;
    border-color: black;
    border-style: solid;
  }
  .PDFCont{
    width: 98%;
  }
  .HomeCont4 {
    grid-row: 17;
  }
  .SecondaryText {
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
    width: auto;
  }
  .SecondaryGrid {
    width: 85%;
  }
  .SecondaryTextCont {
  }
  .backImg {
    position: inherit;
  }
  .SecondaryTextBox {
    margin-left: 0px;
    align-items: center;
  }
  .SecondaryTextLine {
    padding-left: 0px;
    width: 100%;
    margin-left: 0px;
    flex-direction: column-reverse;
    display: flex;
    align-items: center;
  }
  .SecondaryTextLineLink {
    margin-left: 50px;
    margin-right: 50px;
  }

  .SecondaryTextBoxLink {
    margin-left: 0px;
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 1000px) {
  .parallaxChannel {
    background-position: center;
  }
}
@media only screen and (max-width: 750px) {
  .triangleBR {
    border-width: 0 0 10vw 100vw;
  }
  .InfoBar {
    display: flex;
  }

  .SecondaryTextLineLink {
    flex-direction: column;
  }
  .GridCont {
    padding-left: 0;
    padding-top: 0%;
    min-height: 90vh;
  }
  .triangleBL {
    border-width: 10vw 100vw 0 0;
    border-color: #d7d7d7 transparent transparent transparent;
  }
  .parallax {
    background-attachment: scroll;
  }
  .SecondaryImg {
    max-width: 90vw;
    width: 100%;
  }
  .SecondaryTextTitle {
    margin-top: 0px;
    padding-top: 0;
    top: 0;
    width: 100%;
    flex: 1 1;
    height: 100vh;
    align-items: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    font-size: 70px;
  }
  .SecondaryText {
    width: auto;
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 4%;
    text-align: left;
    margin-right: 0%;
  }

  .SecondaryImgCont {
    margin-top: 10%;
  }
  .SecondaryBody {
    flex-direction: column;
  }
  .LogoHome {
    width: 150%;
  }
  .NavTitle {
    font-size: 4vw;
  }
  .HomebarVertLeft {
    display: none;
  }
  .HomeTitleLogo {
    height: inherit;
  }
  .ContainerHeader {
    height: 5%;
    background-color: rgb(105, 163, 51);
  }
  .BtnMiddle {
    font-size: 3vw;
    margin-left: 10px;
    color: white;
  }
  .BtnRight {
    font-size: 3vw;
    margin-left: 10px;
    margin-right: 10px;
    color: white;
  }
  .HomeTitle {
    font-size: 2vw;
  }
  .HomeEmail {
    grid-row: 10;
    width: 100%;
    height: 100%;
    justify-items: center;
    align-items: center;
  }
  .EmailComp {
    margin-left: 5%;
    width: 90%;
    height: 100%;
  }
  .HomeEmptyThree {
    margin-left: 35%;
  }

  .SecondaryLink {
    margin-left: 0%;
    margin-bottom: 4%;
    width: 100%;
    font-size: 3.5vw;
  }
  .LinkOne {
    margin-left: 0%;
    margin-bottom: 4%;
    grid-column: 1/8;
    grid-row: 16/19;
    width: 90%;
    font-size: 3.5vw;
  }
  .Home3TextCont{
    margin-top: 5%;
    width: 100%;
    margin-bottom: 5%;
  }
  .LinkTwo {
    margin-top: 0%;
    margin-left: 0%;
    margin-right: 0%;
    grid-column: 1/8;
    grid-row: 19/22;
    width: 90%;
    font-size: 3.5vw;
  }
  .LogoOne {
    margin-top: 0%;
    margin-left: 0%;
    margin-right: 0%;
    grid-column: 1/8;
    grid-row: 7/9;
    width: 90%;
    font-size: 3.5vw;
  }

  .LogoTwo {
    margin-top: 0%;
    margin-left: 0%;
    margin-right: 0%;
    grid-column: 1/8;
    grid-row: 9;
    width: 90%;
    font-size: 3.5vw;
  }
  .TextTwo {
    margin-top: 0%;
    margin-left: 0%;
    padding-right: 0%;
    grid-column: 1/8;
    grid-row: 10/12;
    font-size: 3.5vw;
  }
  .special {
    text-decoration: none;
    padding-top: 3%;
    border-bottom: 0;
    display: block;
    font-family: Raleway, Helvetica, sans-serif;
    letter-spacing: 1px;
    font-weight: 800;
    font-style: normal;
    font-size: 2vw;
    text-transform: uppercase;
    color: rgb(105, 163, 51);
  }
  .HomeCaras {
    grid-row: 12/15;
    width: 100%;
    grid-column: 1/8;
    padding: 0%;
    border-width: 0;
    margin-top: 0%;
    margin-bottom: 0%;
  }
  .HomeCont1{
    grid-row: 17;
  }

  .HomeCarasTest {
    width: 90%;
    padding: 0%;
    border-width: 0;
  }
  .HomeBarTwo {
    height: 2rem;
    grid-row: 5;
  }
  .HomeEmptyTwo {
    grid-row: 9/10;
  }

  .HomeEmptyFour {
    font-size: 3vw;
  }
  .HomeBarThree {
    grid-row: 6;
  }

  .HomeBarFour {
    grid-row: 22;
  }
  .HomeEmail {
    grid-row: 23;
    grid-column: 1/8;
    width: 95%;
  }
  .GridSample1 {
    width: 95%;
    margin-left: 2.5%;
    padding: 0%;
    padding-top: 10%;
  }
  .Car {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .carousel {
    border-radius: 20px;
  }
  .carPic {
    overflow: hidden;
    object-fit: cover;
    aspect-ratio: 1.15;
  }
  .GridSample2 {
    width: 95%;
    margin-left: 2.5%;
    padding: 0%;
    padding-top: 10%;
  }
  .GridSample3 {
    width: 95%;
    margin-left: 2.5%;
    padding: 0%;
    padding-top: 10%;
  }
  .GridSample4 {
    width: 95%;
    margin-left: 2.5%;
    padding: 0%;
    padding-top: 10%;
  }
  .Message {
    padding: 12px 20px;
  }
  .Email {
    width: 100%;
    padding: 12px 20px;
  }
  .Submit {
    margin-left: 0%;
    width: 100%;
  }

  .GridText {
    font-size: 1.5vh;
    color: white;
    font-family: Raleway, Helvetica, sans-serif;
    letter-spacing: 1px;
    font-weight: 800;
    font-style: normal;
    font-size: 1vw;
  }

  .GridTextTitle {
    color: white;
    font-family: Raleway, Helvetica, sans-serif;
    letter-spacing: 1px;
    font-weight: 800;
    font-style: normal;
    font-size: 1vw;
  }
  .Btn {
    margin-right: -100%;
    text-align: left;
    display: block;
    transition: margin-right 0.5s ease;
  }
  .BtnLeft {
    padding: 0px;
    margin: 0px;
    display: block;
    font-size: 3vw;
    padding-top: 0px;
  }

  .ContainerNavbar {
    position: fixed;
    display: flex;
    width: 70%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    border-right: 0.3px;
    border-left: 0px;
    border-top: 0px;
    border-bottom: 0px;
    border-color: white;
    border-style: solid;
    align-items: center;
    left: -70.2%;
    transition: left 0.5s ease-in;
    top: 0%;
  }

  .BtnS {
    align-self: flex-end;
    text-align: center;
    width: 0%;
    background-color: #1d1a1d;
    flex: 0.5 1;
    align-items: center;
    justify-content: center;
    /*margin-top: 0.3rem;
    margin-bottom: 0.3rem;*/
    transition: padding-top 0.5s, padding-bottom 0.5s;
  }
  .NavBtnFlex {
    margin-left: 0%;
    margin-right: 0%;
    width: fit-content;
    padding-right: 1.5em;
    padding-left: 0rem;
    flex-direction: column;
    height: 100%;
    margin: 0px;
    margin-top: 30%;
  }

  .nav-link-cont {
    display: flex;
    position: absolute;
    top: 1em;
    right: calc(-3em - 0.1px);
    width: 3em;
    height: 3em;
    background-color: #1d1a1d;
    justify-content: center;
    align-items: center;
    opacity: 1;
    border-right: 0.3px;
    border-left: 0px;
    border-top: 0.3px;
    border-bottom: 0.3px;
    border-color: white;
    border-style: solid;
    left: auto;
  }
  .nav-link {
    position: absolute;
    width: fit-content;
    height: fit-content;
    transition: transform 400ms ease;
    justify-content: center;
    transform: rotate(270deg);
    top: auto;
  }
  .Footer-foot {
    margin-left: 1%;
    grid-column: 1/5;
    grid-row: 2;
    align-self: end;
    text-align: left;
  }

  .Footer-Contact {
    margin-left: 5%;
    grid-column: 1/8;
    grid-row: 1;
    text-align: left;
    margin-bottom: 0%;
  }
}
